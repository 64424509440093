<template>
  <div>
    <b-overlay
      :show="showLoading"
      variant="dark"
      :opacity="0.25"
      rounded="sm"
    >
      <b-row>
        <b-col cols="12" md="7">
          <b-card>
            <b-row>
              <b-col cols="12" md="6">
                <h2>
                  {{ purchaseRequest.code }}
                </h2>
                <h6 class="card-text">
                  {{ purchaseRequest.requestor_contact ? purchaseRequest.requestor_contact.pic_name : '' }}
                </h6>
                <p class="card-text mb-0">
                  {{ purchaseRequest.requestor_contact ? purchaseRequest.requestor_contact.pic_address : '' }}
                </p>
                <p class="card-text mb-0">
                  {{ purchaseRequest.work_unit ? purchaseRequest.work_unit.name : '' }}
                </p>
                <p class="card-text mb-0">
                  {{ purchaseRequest.remark }}
                </p>
              </b-col>

              <b-col cols="12" md="6" class="text-right">
                <h4>
                  Priority: <b-badge
                    v-if="purchaseRequest.priority"
                    pill
                    :variant="`${value === 'URGENT' ? 'light-primary' : 'light-danger'}`"
                  >{{ purchaseRequest.priority }}</b-badge>
                  <span v-else>-</span>
                </h4>
                <h6 class="card-text">
                  Request Date: {{ purchaseRequest.date }}
                </h6>
                <p class="mb-0">
                  {{ purchaseRequest.product_category ? purchaseRequest.product_category.name : '' }}
                </p>
                <!-- <p class="card-text mb-0">
                  {{ purchaseRequest.requestor_contact.pic_address }}
                </p>
                <p class="card-text mb-0">
                  {{ purchaseRequest.work_unit.name }}
                </p>
                <p class="card-text mb-0">
                  {{ purchaseRequest.remark }}
                </p> -->
              </b-col>

              <b-col cols="12" class="mt-5">
                <b-table
                  id="refListTable"
                  ref="refListTable"
                  no-provider-paging
                  no-provider-filtering
                  :fields="tableColumns"
                  :items="purchaseRequest.details"
                  responsive
                  primary-key="id"
                  show-empty
                  class="position-relative"
                  small
                >
                </b-table>
              </b-col>

              <b-col cols="12">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  variant="outline-primary"
                  @click="handleBack()"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  {{ $t('globalActions.backToList') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="5">
          <!-- <b-card>
            <b-row>
              <b-col cols="auto" class="font-weight-bolder">
                <div>PR</div>
                <div>AV</div>
                <div>AP</div>
                <div>PO</div>
                <div>PI</div>
                <div>GR</div>
              </b-col>
              <b-col cols="0">
                <div>:</div>
                <div>:</div>
                <div>:</div>
                <div>:</div>
                <div>:</div>
                <div>:</div>
              </b-col>
              <b-col>
                <div>Purchase Request</div>
                <div>Assign Vendor</div>
                <div>Assign Price</div>
                <div>Purchase Order</div>
                <div>Purchase Invoice</div>
                <div>Goods Recipient</div>
              </b-col>
            </b-row>
          </b-card> -->

          <b-card>
            <b-row>
              <b-col cols="12">
                <h2>Status</h2>
                <b-progress
                  height="1.1rem"
                  class="mb-2"
                  :max="7"
                >
                  <b-progress-bar
                    variant="success"
                    :value="progressStatus"
                  >
                    <strong>{{ progressStatus }} / 7</strong>
                  </b-progress-bar>
                </b-progress>

                <app-timeline>
                  <app-timeline-item
                    v-for="(progress, index) in _progresses"
                    :key="index"
                    :variant="progress.variant || 'primary'"
                    class="pl-2"
                  >
                    <template #prepend>
                      <span
                        :class="`font-weight-bolder bg-${progress.variant || 'primary'} text-white rounded-circle`"
                        style="padding: 5px; margin-left: -3px"
                      >{{ progress.typeCode }}</span>
                    </template>
                    <b-row>
                      <b-col cols="12">
                        <b-link
                          :to="{ name: progress.pathName, params: { id: progress.id }}"
                          class="font-weight-bolder"
                        >
                          {{ progress.code }}
                        </b-link>
                        <hr style="margin-top: 3px; margin-bottom: 3px;">
                      </b-col>
                      <b-col>
                        <p class="mb-0 font-weight-bolder">{{ progress.status }}</p>
                        <p class="mb-0">{{ progress.description }}</p>
                      </b-col>
                      <b-col cols="0" class="text-right pr-1">
                        <p class="mb-0">{{ progress.date }}</p>
                      </b-col>
                    </b-row>
                  </app-timeline-item>
                </app-timeline>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BTable,
  BProgress,
  BProgressBar,
  BLink,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted, computed } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import useLocalization from '@/comp-functions/utils/useLocalization'
import router from '@/router'
import moment from 'moment'


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BTable,
    BProgress,
    BProgressBar,
    BLink,
    BOverlay,
    BButton,
    AppTimeline,
    AppTimelineItem,
  },


  setup () {
    const { $get } = useHttp()
    const { $t } = useLocalization()
    // const paramsId = router.currentRoute.params.id

    const showLoading = ref(false)
    const purchaseRequest = ref({})
    const _progresses = ref([])
    const tableColumns = [
      {
        key: 'product.name',
        label: $t('globalSingular.product'),
        thClass: 'bg-transparent',
      },
      {
        key: 'product.uom.uom',
        label: $t('globalSingular.unit'),
        thClass: 'bg-transparent text-center',
        tdClass: 'text-center',
      },
      {
        key: 'quantity',
        label: $t('globalSingular.quantity'),
        thClass: 'bg-transparent text-right',
        tdClass: 'text-right',
        formatter: (value) => formatCurrency(value, false),
      },
    ]


    const progressStatus = computed(() => {
      let _count = 1 // Request created

      // Assign Vendor
      const _vendors = purchaseRequest.value.suppliers || []
      if (_vendors.length > 0 && purchaseRequest.value.vendor) {
        if (_vendors[0].id) {
          _count++
        }
      }

      // Approved Request
      const _approvals = (purchaseRequest.value.approvals || [])
      if (_approvals.length > 0) {
        const _approveds = _approvals.filter(apr => apr.is_approved === true)
        if (_approveds.length === _approvals.length) {
          _count++
        }
      }

      // Order
      const _purchaseOrder = purchaseRequest.value.purchase_order
      if (_purchaseOrder) {

        // Approved Order
        const _approvals = (_purchaseOrder.approvals || [])
        if (_approvals.length > 0) {
          const _approveds = _approvals.filter(apr => apr.is_approved === true)
          if (_approveds.length === _approvals.length) {
            _count++
          }
        }

        // Invoice Created
        if ((_purchaseOrder.purchase_invoice_details || []).length > 0) {
          _count++
        }

        // GR Created
        if (_purchaseOrder.goods_recipient) {
          _count++
        }
      }

      return _count
    })


    async function setTimelineProgress() {
      _progresses.value = [
        // PR Created
        {
          id: purchaseRequest.value.id,
          code: purchaseRequest.value.code,
          typeCode: 'PR',
          status: 'Requested',
          variant: 'success',
          description: purchaseRequest.value.created_user
            ? `by ${purchaseRequest.value.created_user.user_name}`
            : '',
          date: purchaseRequest.value.date,
          pathName: 'apps-transactions-purchase-request-list'
        },
        // Pending Assign Vendor
        {
          id: purchaseRequest.value.id,
          code: purchaseRequest.value.code,
          typeCode: 'AV',
          status: 'Pending for Vendors',
          variant: 'warning',
          description: '',
          date: purchaseRequest.value.date,
          pathName: 'apps-transactions-purchase-request-assign-vendor-detail'
        },
      ]

      const _run = async () => {
        // Vendor Assigned
        const _vendors = purchaseRequest.value.suppliers || []
        if (_vendors.length > 0) {
          if (_vendors[0].id) {
            await Promise.all(_vendors.map(vendor => {
              _progresses.value.push({
                id: purchaseRequest.value.id,
                code: purchaseRequest.value.code,
                typeCode: 'AV',
                status: 'Vendor',
                variant: 'success',
                description: vendor.name || '',
                date: moment(purchaseRequest.value.updated_at).format('DD/MM/YYYY'),
                pathName: 'apps-transactions-purchase-request-assign-vendor-detail'
              })
            }))
          } else {
            return;
          }

          // Pending for Assign Price
          _progresses.value.push({
            id: purchaseRequest.value.id,
            code: purchaseRequest.value.code,
            typeCode: 'AP',
            status: 'Pending for Price',
            variant: 'warning',
            description: '',
            date: moment(purchaseRequest.value.updated_at).format('DD/MM/YYYY'),
            pathName: 'apps-transactions-purchase-request-assign-price-detail'
          })
        }

        // PR Approval
        const _approvals = (purchaseRequest.value.approvals || [])
        if (_approvals.length > 0) {
          // Pending for PR Approval
          await Promise.all(_approvals.map(approval => {
            _progresses.value.push({
              id: purchaseRequest.value.id,
              code: purchaseRequest.value.code,
              typeCode: 'AP',
              status: `Pending for ${approval.module_approval.name}`,
              variant: 'warning',
              description: approval.user_approved ? `from ${approval.user_approved.user_name}` : '',
              date: moment(approval.created_at).format('DD/MM/YYYY'),
              pathName: 'apps-transactions-purchase-request-assign-vendor-detail'
            })
          }))
        } else {
          return;
        }
      }

      await _run()
      _progresses.value.reverse()
    }


    async function getPurchaseInvoice() {
      showLoading.value = true
      await $get({
        url: `transaction/purchase/request/${router.currentRoute.params.id}`,
      })
        .then((result) => {
          purchaseRequest.value = result.data || result
        })
        .finally(() => {
          showLoading.value = false
        })
    }


    onMounted(async () => {
      await getPurchaseInvoice()
      await setTimelineProgress()
    })

    return {
      purchaseRequest,
      tableColumns,
      progressStatus,
      _progresses,
      getPurchaseInvoice,
      showLoading,
    }
  },

  methods: {
    handleBack() {
      this.$router.back()
    },
  }
}
</script>


<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
